<template>
  <div>
    <van-list :offset="15">
      <div v-if="flag === true">
        <div v-for="(item, i) in list" :key="i" class="yun_newedition_resume">
          <div class="yun_newedition_resumelist">
            <a @click="toInfo(item)">
              <div class="yun_newedition_resume_wantjob">
                <span class="yun_newedition_resume_wantjob_n">
                  {{ item.ShopName }}
                </span>
              </div>
              <div class="user_undergo_box">
                <div class="user_undergo user_undergo_a">
                  评分结果：{{ item.Grade }}
                </div>
              </div>
              <div class="user_undergo_box">
                <div class="user_undergo user_undergo_a">
                  评分时间：{{ item.Date_OP }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <van-empty v-else description="暂无记录，请重新查询" />
    </van-list>
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import Base64 from '@/util/Base64'
  import { Toast } from 'vant'
  import { getShopGradeList } from '@/api/outDoor/shopGrade/shopGrade'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    data() {
      return {
        list: [],
        flag: true,
        queryForm: {
          ShopID: null,
        },
      }
    },
    created() {
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      this.queryForm.ShopID = Base64.decode(this.$route.query.shopID)
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'shopGrade',
        '城管-店铺评分列表',
        'zzdcg.yy.gov.cn/shopGrade'
      )
    },
    methods: {
      //获取数据
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getShopGradeList(this.queryForm)
        Toast.clear()
        if (data.length > 0) this.list = data
        else this.flag = false
      },

      //跳转至详情
      toInfo(item) {
        this.$router.push({
          path: 'ShopGradeInfo',
          query: {
            Id: Base64.encode(item.ID),
          },
        })
      },
    },
  }
</script>

<style scoped>
  .yun_newedition_resume_wantjob {
    padding-bottom: 0.266666rem;
    font-size: 0.426666rem;
    color: #666;
  }
  .yun_newedition_resume_wantjob_n {
    font-size: 0.426666rem;
    font-weight: bold;
    color: #000;
  }
  .yun_newedition_resumelist a {
    position: relative;
    display: block;
    padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  }
  .new_userlist_p {
    position: relative;
    padding-bottom: 0.266666rem;
    font-size: 0.346666rem;
    color: #333;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .yun_newedition_resume {
    padding: 0rem 0.32rem 0rem 0.32rem;
  }
  .yun_newedition_resumelist {
    margin-top: 0.266666rem;
    background: #fff;
    border-radius: 0.213333rem;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
    padding-left: 0.666666rem;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../assets/images/workBench/advise/icon.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
</style>
